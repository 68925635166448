import Casco from '../src/assets/Casco.jpg'
import Pan from '../src/assets/panDeJamon.jpg'


function App() {
  return (
    <section className="prose prose-slate lg:prose-lg dark:prose-invert max-w-none">
    <div className='max-w-6xl mx-auto'>
      <h1 className="text-gray-600">Manifiesto de Valencia</h1>
      <p className="italic text-xs">Elaborado 12/12/2021 / Publicado 17/12/2021 / Modificado 09/12/2023</p>
      <p className="lead">Desde hace unos años, he estado observando cómo se desenvuelve nuestro entorno y como va nuestro mundo. Hace rato que manifiesto a través de mis redes sociales la inconformidad por la pérdida de <strong>principios y valores</strong>, por ello me motivé a leer e investigar las bases que mueven estos dos conceptos, haciendo salvedad que no soy filósofo, ni letrado. Simplemente deseo entender cómo descifrar todo este entorno que vivimos.</p>
      
      <p>Para ello es necesario que mis definiciones sean lo más cercanas a las que tú manejas y quisiera comenzar por definir los principios y valores, basado en información encontrada en Internet, extraigo un fragmento importante de <a href="https://concepto.de/principios/"> www.concepto.de</a></p>
      <h2 className="">¿Qué son los principios?</h2>
      <blockquote>
        “En el campo de la ética, los principios son <strong>el conjunto de normas generales y universales con las que los seres humanos orientamos nuestras acciones</strong> y nuestra conducta, dentro de un marco ético, moral y cultural determinado.<br />
        La mayoría de las doctrinas, religiones y códigos de algún tipo se basan en unos principios bien establecidos, que fundamentan y estructuran toda una cadena de valores, es decir, que le dan forma a un modo de estar en el mundo.<br />
        Los principios se llaman así porque se hallan en el comienzo, en la base, de todo edificio moral o social. Es decir, se trata de preceptos fundamentales generalmente tenidos como <strong>beneficiosos no sólo para el individuo, sino para toda la humanidad</strong>.”
      </blockquote>
      <h2 className="">¿Qué son los valores?</h2>
      <blockquote>
        Los valores son aptitudes o cualidades individuales que definen la conducta de un individuo en la sociedad. Dichos valores derivan de los principios éticos universales, de allí que su objetivo sea guiar a los individuos a obrar correctamente, de forma individual y colectiva…
        <br /><br />
        …Los valores según el origen de sus preceptos pueden ser: éticos, morales, religiosos, cívicos, familiares, personales.”

      </blockquote>
      <p>
        Basado en estas definiciones, me detengo un poco en los valores, entendiendo como valores de forma general los elementos más destacados para una convivencia armoniosa.
        Leyendo un poco, encontré una frase de <strong>Caterina Chen</strong> que dice:</p>
      <blockquote>
        “Sin los valores como referencia frente a nuestra forma de actuar individual y hacia los demás, las relaciones se debilitan al no albergar criterios comunes para la vida en sociedad”.
      </blockquote>
      <p className="italic text-xs">Chen, Caterina (28/06/2019). "Los 10 valores más importantes en la sociedad y sus significados". En: Significados.com. Disponible en: https://www.significados.com/los-10-valores-mas-importantes-en-la-sociedad-y-sus-significados/ Consultado: 12 de diciembre de 2021, 07:38 am.</p>
      <h3 className="">
        ¿Pero cuáles son esos valores, o al menos ¿cómo se comen?,¿Cómo sé yo que estoy en el camino correcto?
      </h3>
      <div className='bg-cyan-50 p-2'>
        <p>Revisando un poco más, diferentes sitios en Internet enumeran los <strong>valores</strong> de forma general de la siguiente manera:</p>
        <ul>
          <li>Respeto</li>
          <li>Amor</li>
          <li>Libertad</li>
          <li>Justicia</li>
          <li>Tolerancia</li>
          <li>Equidad</li>
          <li>Paz</li>
          <li>Honestidad</li>
          <li>Responsabilidad</li>
          <li>Lealtad</li>
          <li>Gratitud</li>
          <li>Amistad</li>
          <li>Solidaridad</li>
        </ul>
      </div>
      <p>Ahora bien, asumiendo que estamos en sintonía. Ustedes se preguntarán: Ok, ¿más o menos?.</p>
      <h3 className="">Vamos a ver si te sientes identificado con alguno de estos escenarios:</h3>
      <ul>
        <li>¿Alguna vez algún amigo te ha pedido ayuda, lo has sentado a comer en la sagrada mesa de tu hogar a compartir con tu familia y, se han llevado algo tangible o intangible de tu casa o negocio?</li>
        <li>¿Has tenido personas que dicen ser tus amigos y resultan ser desleales?</li>
        <li>¿Familiares que ayudas cuando tienes posibilidades, pero cuando no las tienes eres señalado?</li>
        <li>¿Has llegado al punto de dejar todo por escrito, porque la palabra no tiene ningún valor?</li>
        <li>¿Le has dedicado tiempo valioso a alguien que solo quería la hora?</li>
        <li>¿Qué debes vivir con el dogma del materialismo?</li>
        <li>¿Cuántas veces han dañado tu reputación con comentarios fabricados a conveniencia? ¿Incluso que te hayan perjudicado legalmente?</li>
        <li>Y la peor de todas: ¿te han visto la cara de pendejo?</li>
      </ul>
      <p>
        Quizás te sientas identificado con alguno de estos elementos o antivalores, o es muy probable que falten muchos. Pero a ello debemos sumarle que vivimos en un mundo en donde la puja por el poder ya no es solo material, es espiritual, es una guerra que trata de remover las bases de la conciencia para crear desasosiego. <br/>
        ¿Con qué fin? <br/>
        Se los dejo a su creatividad.
      </p>
      <p className='bg-orange-100 p-2'>
        <strong>Las redes sociales</strong>, por ejemplo, son parte de esa puja de poder, se han convertido en <strong>un culto al ego o mostrar simplemente la vida que no tienen</strong>. Pónganse a pensar por un instante, el daño que le han hecho las <em>redes sociales</em> a los <em>principios y valores</em>.
        Paradójicamente yo me he beneficiado de ellas desde un punto de vista comercial o industrial (es decir, desde el punto de vista de la mercadotecnia), he dado conferencias, talleres, asesorías, pero no basado en exacerbar de cualidades y características ficticias. Si te detienes a analizar, en la redes tú puedes construir prácticamente una profesión en cuestión de minutos, como:<em>Psicólogos que no son psicólogos</em>, gurus del marketing basados en cuentas de chistes con memes, ni hablar del fenómeno de los <em>coaching con fines netamente monetarios</em> y, lo más grave aún <em>supuestos médicos practicando la medicina sin haber estudiado medicina</em>. <br/>
        En fin, Es infinito el mundo de posibilidades y, básicamente, porque cada uno de ellos construye una historia, que al final alguien la compra y la sigue. Y luego se masifican generando los mal llamados influenciadores (influencers). Y ustedes me dirán: <em>- no puedes negar que hay líderes e influenciadores reales emergentes de estos fenómenos comunicacionales como son las redes sociales</em>. ¿Pero cuál es la proporción?
      </p>
      <p>
        No voy a detenerme en el punto del <strong>movimiento a la inclusión</strong>, creo que hay mucha manipulación y marketing en cuanto a este tema, es algo que está en desarrollo; he leído y visto entrevistas al respecto y se debe tener precaución al abordar el tema. Lo que sí voy a decir, es que definitivamente es una variable que afecta los principios y valores. Porque hay que reconocer su existencia de forma transparente y que esa inclusión no es solo de género sino de forma, de raza y de credo. No estoy de acuerdo que ciertas patologías psiquiátricas a través del poder del dinero y el marketing traten de victimizarse y apegarse al movimiento de la inclusión, pero si debemos seguir abogando por respetarnos los unos a los otros siguiendo los <em>principios(éticos) y valores</em>.
      </p>
      
      <figure>
        <img src={Casco} alt="Venezuela" />
        <figcaption>Ruta Pregonero-Barinas Junio 2018</figcaption>
      </figure>
      <p>
        Aterrizando en mi país, <strong>Venezuela</strong>: Hago referencia al gran comediante intelectual Emilio Lovera y al ilustre Laureano Marquez, que categorizan a Venezuela como <strong>el mejor país del mundo</strong>; yo comparto su creencia. Venezuela es un país rico en recursos naturales, en donde hemos tenido la dicha de vivir de la influencia de los dos mundos, el norte americano y el europeo.  Con la chispa caribeña, con el clima tropical, en donde muy pocos cataclismos naturales ni guerras han cambiado la idiosincrasia del venezolano. Aquí el gran problema es otro y somos nosotros mismos, no importa de qué bando seamos. Seguimos cometiendo los mismos errores del pasado una y otra vez, sin detenerse a pensar que el secreto está en pensar en el bien común y no privar en lo individual (bien sea personas o grupos sociales). Estoy seguro que existen libros completos de nuestra historia contemporánea y sus disparatadas anécdotas.
      </p>
      <p className='bg-orange-100 p-2'>
        Sin gas, con gas; sin luz, con luz; sin comida, con comida; sin gasolina, o con gasolina. Organizaciones benéficas globales colectando la buena fe de la gente en muchas partes del mundo para ayudar a los pobres venezolanos. Pero el carnaval, la Semana Santa y Los Juanes en Morrocoy se respetan. Voy a misa los domingos a treinta y ocho grados celsius en sombra, pero tengo mi brujo o santero de confianza que me da el empujón cuando lo necesito. <br /> Somos una mezcla racial de muchas vertientes, pero también somos una mezcla peculiar de muchas costumbres y pensamientos. No se si eso ha sido perjudicial o no, porque muchos critican la mezcla genética de nuestros conquistadores con nuestros originarios, pero hay muchos venezolanos que se han ido y hasta en la NASA han ido a parar, entonces el cóctel no es tan malo. Nuestro mismo Simón Bolívar es un ejemplo de ello y, estoy seguro que hay una lista inmensa de esos ejemplos.
      </p>
      <p>
        <strong>Muchos se han ido</strong>, por circunstancias diversas o específicas. En busca de calidad de vida. Pero se vuelven esclavos de otros sistemas de forma perpetua, en donde no disfrutan a su familia o el amor del hogar por mantener el status de <em>“calidad de vida”</em>.
      </p>
      <hr />
      <p>
        Es difícil comprender la altisonancia del entorno que vivimos hoy en día siendo adultos, pero aún más complejo es <strong>como explicarle a nuestros hijos que es lo bueno y qué es lo malo</strong>. ¿Cómo cultivar nuestros principios y valores, con un entorno tan dinámico y complejo?, y peor aún, que hagas un tremendo esfuerzo por sembrar la semilla y la exposición en las redes sociales o sitios prohibidos, destruya tu esfuerzo en segundos.
      </p>
      <p>
        Miren, la historia contemporánea le ha demostrado al mundo, que <strong>el Venezolano se resiste a perder sus costumbres</strong>, que le quiten la navidad, que disfrute de una parrillada un domingo, de ir el fin de semana a la playa. De ir de vacaciones por el país o por el mundo, de tener casa en el campo o en la playa. De tener ambiciones sanas, tener su casita, su carro. ¿Y porqué no? ¿Cuál es el pecado de evolucionar en función de tu crecimiento personal, del fruto de tu esfuerzo?. <strong>Somos una raza excepcional</strong>, trabajadora, con las pilas puestas. Analicen lo que ha pasado con aquellos países en donde hay volumen de venezolanos preparados académicamente y con algo de capital. Profesionales que llegaron dominando varios idiomas, con maestrías y posgrados. Detenganse a pensar en países como Panamá y Perú que hoy en día nos discriminan. Pero cuando los venezolanos se aparecían con las tarjetas de CADIVI los amaban. Siempre con las puertas abiertas. Y me molesta profundamente que permitamos que pisoteen nuestro gentilicio. Personas de las buenas o de las malas, porque al final del camino algo de la gente improductiva también se fue. <em>Jamás pensé ver a venezolanos de balseros o peor aún, cruzando la frontera de Estados Unidos a la merced de los coyotes</em>. Es como una película de terror, en donde no sabemos si debemos salir corriendo o jugar a la ere paralizada.
      </p>
      <figure>
        <img src={Pan} alt="Pan de Jamón" className='w-1/3 mx-auto' />
        <figcaption className='text-center'>Pan de Jamón de @evegourmet Dic 2021</figcaption>
      </figure>
      <p>
        Por otro lado, <strong>la diáspora</strong> venezolana por el mundo <strong>ha esparcido nuestros aromas y sabores</strong>. Así como nuestra gastronomía, los rones y la música. Piensen un poco en ello. Hoy en día no hay un rincón en donde no se conozca nuestra arepa, la hallaca, el pan de jamón <em>(soy un fanático del pan de jamón)</em> y hasta los pepitos.
      </p>
      <div className='bg-cyan-50 p-2'>
      <p>
        Ya en lo personal, pienso que debemos hacer una revisión de aquello en lo que debemos priorizar, yo como buen crítico y autocrítico me tomo el atrevimiento de tomar la palabra y denotar un manifiesto en lo que pienso debemos trabajar:
      </p>
      <ol>
        <li>
          <h3 className="">Debemos cultivarnos con inteligencia emocional, espiritualmente y físicamente.</h3>
          <p>Tienes que ser vigilante de lo que piensas y de lo que comes. No es casual que la gerencia moderna vea lo espiritual como elemento fundamental para el éxito.</p>
        </li>
        <li>
          <h3 className="">La puntualidad y el compromiso ante nuestras responsabilidades.</h3>
          <p>Son claves para rescatar la palabra. <strong>La palabra</strong> debe ser el arma más poderosa que tengamos. Esto debe ser bidireccional, no en un solo sentido.</p>
        </li>

        <li>
          <h3 className="">Nuestros hogares son el templo y la base de lo que somos.</h3>
          <p>Desde aquí proyectamos a nuestras comunidades, para ser influyentes y contribuir con lo que nos rodea. Si no nos involucramos con los problemas de nuestras comunidades, no podremos avanzar.</p>
        </li>
        <li>
          <h3 className="">Esfuérzate en ser auténtico.</h3>
          <p>Es cierto que debemos armar nuestras estrategias para crecer no solo como personas, sino también económicamente. Pero procura no hacerlo con atropellos, hazlo con inteligencia y preparación. El abanico de opciones es muy amplio, hay espacio para todos.</p></li>
        <li>
          <h3 className="">Debemos enfocarnos en estar con personas que manejen los mismos códigos.</h3>
          <p>Y fortalecer nuestras comunidades de relaciones. No como sociedades ocultas, ni mucho menos conspirativas. Sino más bien de forma fraternal.</p>
        </li>
        <li>
          <h3 className="">La gratitud ante las bendiciones que la vida nos permite disfrutar.</h3>
          <p>Con amor, buenas amistades, sin envidia, sin dejar de lado el medio ambiente que nos rodea, respetando los espacios.</p>
        </li>
        <li>
          <h3 className="">Lealtad a nuestros principios y valores.</h3>
          <p>Está comprobado que uniendo esfuerzos en comunidad, compartiendo esos principios y valores, y llevándolos a la práctica tendremos éxito. No puedes ser leal con aquellos que no predican tu esencia y creencia.</p>
        </li>
      </ol>
      </div>
      <p>
        Mi querido lector, si has llegado hasta aquí el mensaje es claro. Debemos insistir y persistir en reforzar nuestros principios y valores. Ese será nuestro mayor legado.
      </p>
      <h3 className="">Raúl Story</h3>
      <p className="italic text-xs">PD: Este manifiesto contó con la lectura previa de amigos, abogados, entusiastas del tema de diversidad de género, médicos y políticos. En función de ser lo más objetivo y neutral posible. Aquí solo se señalan los antivalores como el foco principal de la dicotomía social.</p>
    </div>
  </section>
  );
}

export default App;
